/**
 * Disable main menu dropdown on desktop >= 992 px
*/

const disableMenuDropdownDesktop = (() => {
    let elements = document.querySelectorAll('.main-header-menu [data-bs-toggle="dropdown"]');

    for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', (e) => {
            e.preventDefault();

            if (get_vw() > screen_sm_max && elements[i].getAttribute('href') && elements[i].getAttribute('href') != '#') {
                window.location = elements[i].getAttribute('href');
            }
        });
    }
})();

export default disableMenuDropdownDesktop;