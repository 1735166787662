/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function () {
    $(document).on('click', '.menu, .close-menu', function(e) {
        e.preventDefault();
        $('#main-menu').toggleClass('active');
    });

    if ($('#photos-listing').length && get_vw() > screen_md) {
        tns({
            container: '#photos-listing',
            controlsContainer: '.news-photos .arrows',
            items: 4,
            gutter: 15,
            autoplayButtonOutput: false,
            autoplay: false,
            nav: false,
            navPosition: 'bottom',
            slideBy: 'page',
            controls: true,
            autoplayTimeout: 10000,
            speed: 500,
            loop: false,
        });
    }

    if ($('.benefits').length && get_vw() > screen_md) {
        tns({
            container: '.benefits',
            controlsContainer: '.benefits-wrp .arrows',
            items: 4,
            gutter: 15,
            autoplayButtonOutput: false,
            autoplay: false,
            nav: false,
            navPosition: 'bottom',
            slideBy: 'page',
            controls: true,
            autoplayTimeout: 10000,
            speed: 500,
            loop: false,
        });
    }

    if ($('.editor_type_latest_news .news-list').length && get_vw() > screen_md) {
        tns({
            container: '.editor_type_latest_news .news-list',
            controlsContainer: '.editor_type_latest_news .arrows',
            items: 4,
            gutter: 15,
            autoplayButtonOutput: false,
            autoplay: false,
            nav: false,
            navPosition: 'bottom',
            slideBy: 'page',
            controls: true,
            autoplayTimeout: 10000,
            speed: 500,
            loop: false,
        });
    }

    if ($('.editor_type_nt_projects .nt-projects-list').length && get_vw() > screen_md) {
        tns({
            container: '.editor_type_nt_projects .nt-projects-list',
            controlsContainer: '.editor_type_nt_projects .arrows',
            items: 4,
            gutter: 15,
            autoplayButtonOutput: false,
            autoplay: false,
            nav: false,
            navPosition: 'bottom',
            slideBy: 'page',
            controls: true,
            autoplayTimeout: 10000,
            speed: 500,
            loop: false,
        });
    }

    if (get_vw() > screen_md) {
        var image = document.querySelectorAll('.editor_type_banner img');
        new simpleParallax(image);

        var image = document.querySelector('#nt-projects-detailed .main-photo img');
        new simpleParallax(image);

        var image = document.querySelector('#news-detailed .main-photo img');
        new simpleParallax(image);
    }
});